*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}


html,
body {
  font-family: Lato, Roboto, sans-serif;
  font-weight: 400;
  font-size: 1rem;
  box-sizing: border-box;
}


body,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Lato, Roboto, sans-serif;
  font-weight: 400;
  line-height: 1.5em;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: inherit;
}

h1,
h2,
h3 {
  margin-top: 20px;
  margin-bottom: 10px;
}

h4,
h5,
h6 {
  margin-top: 10px;
  margin-bottom: 10px;
}

h1 {
  font-size: 3.3125rem;
  line-height: 1.15em;
}

h2 {
  font-size: 2.25rem;
  line-height: 1.5em;
}

h3 {
  font-size: 1.5625rem;
  line-height: 1.4em;
}

h4 {
  font-size: 1.125rem;
  line-height: 1.5em;
}

h5 {
  font-size: 1.0625rem;
  line-height: 1.55em;
}

h6 {
  font-size: 0.90rem;
  line-height: 1.5em;
}

p {
  font-size: 1rem;
  margin: 0 0 10px;
}

b,
strong {
  font-weight: 700;
  font-family: Lato, Roboto, sans-serif;
}

html {
  font-family: Lato, Roboto, sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: transparent;
}

body {
  background-color: rgba(255, 255, 255, 0);
  color: rgba(0, 0, 0, 0.90);
  margin: 0;
  font-size: 1rem;
  text-align: left;
}

legend {
  border-bottom: 0;
}

// Prevent highlight on mobile
* {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;

  &:focus {
    outline: 0;
  }
}

a {
  //color: $link-color;
  cursor: pointer;
  text-decoration: none;
  background-color: transparent;

  &:hover,
  &:focus {
    //color: darken($link-color, 5%);
    text-decoration: none;
    cursor: pointer;
  }
}

label {
  font-size: 14px;
  line-height: 1.42857;
  color: #aaaaaa;
  font-weight: 400;
}

small {
  font-size: 75%;
  color: #777;
  font-weight: 400;
}

img {
  vertical-align: middle;
  border-style: none;
}

form {
  margin-bottom: 1.125rem;
}

button {
  cursor: pointer;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}
li {
  font-size: 1rem;
}

.blog-navigation {
  ul {
    margin-bottom: 0;
  }

  ul li {
    margin-left: 2rem;
    padding:4px;
  }
  ul ul li {

    margin-left: 4rem;
    padding: 4px;
  }
  ul li ul li {
    margin-left: 2rem;
    padding:4px;
  }
  ul li ul li ul li {
    margin-left: 2rem;
    padding:4px;
  }
}
.w-md-editor-fullscreen {
  overflow: hidden;
  position: fixed;
  z-index: 1300 !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100% !important;
}
.blogPostText {
  overflow-x: hidden;
  pre {
    background: #fefefe;
    border-left: 4px solid #af1212;
    margin: 1.5rem 10px;
    padding: .5rem 10px;
    font-family: "Lato",serif;
    font-style: italic;
  }

  pre code {
    font-family: "Lato",serif;
    font-style: italic;
  }

  blockquote {
    background: #f9f9f9;
    border-left: 10px solid #ccc;
    margin: 1.5em 10px;
    padding: 0.5em 10px;
  }
  blockquote:before {
    color: #ccc;
    line-height: 0.1em;
    margin-right: 0.25em;
    vertical-align: -0.4em;
  }
  blockquote p {
    display: inline;
  }
  h1 {
    a {
      color: rgba(0, 0, 0, 0.87);
      text-decoration: underline;

    }

  }

  h2 {
    padding: 25px 0 0 0;
    a {
      color: rgba(0, 0, 0, 0.87);
      text-decoration: underline;

    }

  }

  h3 {
    padding: 20px 0 0 0;
    a {
      color: rgba(0, 0, 0, 0.87);
      text-decoration: underline;

    }

  }

  h4 {
    padding: 15px 0 0 0;
    a {
      color: rgba(0, 0, 0, 0.87);
      text-decoration: underline;

    }

  }

  h5 {
    padding: 15px 0 0 0;
    a {
      color: rgba(0, 0, 0, 0.87);
      text-decoration: underline;

    }

  }

  h6 {
    a {
      color: rgba(0, 0, 0, 0.87);
      text-decoration: underline;

    }

  }

  p {
    line-height: 1.5;
    padding:5px 0;
    a {
      color: rgba(0, 0, 0, 0.87);
      text-decoration: underline;

    }

  }

  a {
    color: rgba(0, 0, 0, 0.87);
    text-decoration: underline;

  }

  ul li {
    margin-left: 2rem;
    padding:4px;
  }
  ul ul li {

    margin-left: 4rem;
    padding: 4px;
  }
  ul li ul li {
    margin-left: 2rem;
    padding:4px;
  }
  ul li ul li ul li {
    margin-left: 2rem;
    padding:4px;
  }
}

#images h4 {
  margin-bottom: 30px;
}


q {
  quotes: "“" "”" "‘" "’";
}

q::before {
  content: open-quote;
}

q::after {
  content: close-quote;
}

.read-or-hide {
  color: #4dd0e1;
  cursor: pointer;
}
